import { Injectable } from '@angular/core';
import { QuestionService } from '@examdojo/question';
import { concatMap, forkJoin } from 'rxjs';
import { UserMarksService } from '../user/mark/user-marks.service';

@Injectable()
export class UpdateUserMarksOnAttemptGradedService {
  constructor(
    private readonly questionService: QuestionService,
    private readonly userMarksService: UserMarksService,
  ) {
    this.questionService.attemptGraded$
      .pipe(
        concatMap(() =>
          forkJoin([
            this.userMarksService.fetchLastWeekUserMarks(),
            this.userMarksService.getUserSpentPointsAndLimits(),
          ]),
        ),
      )
      .subscribe();
  }
}
