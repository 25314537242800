import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { FeatureFlagService } from '@examdojo/core/feature-flag';
import { ExamdojoFeatureFlags } from '@examdojo/models/feature-flag';
import { UserQuery, UserService } from '@examdojo/user';
import { isNotNullish } from '@examdojo/util/nullish';
import { map, of, switchMap } from 'rxjs';
import { DEFAULT_URL, RootUrlParts } from '../app.model';

export const onboardingGuard: CanActivateFn = () => {
  const userQuery = inject(UserQuery);
  const router = inject(Router);
  const featureFlagService = inject(FeatureFlagService<ExamdojoFeatureFlags>);

  return featureFlagService.initialized$.pipe(
    switchMap(() => userQuery.isUserOnboarded$),
    map((isUserOnboarded) => {
      if (!isUserOnboarded) {
        return router.createUrlTree([`/${RootUrlParts.Onboarding}`]);
      }
      return true;
    }),
  );
};

export const alreadyOnboardedGuard: CanActivateFn = () => {
  const userQuery = inject(UserQuery);
  const userService = inject(UserService);
  const router = inject(Router);

  return userQuery.isUserOnboarded$.pipe(
    switchMap((isUserOnboarded) => {
      if (!isUserOnboarded) {
        return of(true);
      }

      const user = userQuery.getActive();

      const ensureCurrentOnboardingStepIsReset$ =
        user && isNotNullish(user.current_onboarding_step)
          ? userService.update(user.id, { current_onboarding_step: null })
          : of(undefined);

      return ensureCurrentOnboardingStepIsReset$.pipe(map(() => router.createUrlTree([DEFAULT_URL])));
    }),
  );
};
