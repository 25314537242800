import { Component, inject } from '@angular/core';
import { MatProgressBar } from '@angular/material/progress-bar';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { connectState } from '@examdojo/angular/util';
import { getAnimationAppearDisappearWithHeight } from '@examdojo/animation';
import { AppLoadingService } from '@examdojo/app-state';
import { DebuggingWindowComponent } from '@examdojo/core/debugging';
import { FeatureFlagDirective, FeatureFlagService } from '@examdojo/core/feature-flag';
import { ExamDojoFeatureFlag, ExamdojoFeatureFlags } from '@examdojo/models/feature-flag';
import { LoaderComponent } from '@examdojo/ui/loader';
import { IonApp } from '@ionic/angular/standalone';
import { combineLatest, filter, map, of, startWith, switchMap, takeWhile, timer } from 'rxjs';
import { register as registerSwiper } from 'swiper/element/bundle';
import { APP_EFFECTS } from './effects';
import { IntercomService } from './intercom/intercom.service';

registerSwiper();

@Component({
  selector: 'dojo-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  imports: [IonApp, RouterOutlet, DebuggingWindowComponent, FeatureFlagDirective, LoaderComponent, MatProgressBar],
  animations: [getAnimationAppearDisappearWithHeight()],
  providers: [...APP_EFFECTS],
})
export class AppComponent {
  constructor(
    private readonly router: Router,
    private readonly appLoadingService: AppLoadingService,
  ) {}

  private readonly instantiatedServices = [
    ...APP_EFFECTS,
    IntercomService,
    FeatureFlagService<ExamdojoFeatureFlags>,
  ].map((service) => inject(service));

  protected readonly ExamDojoFeatureFlag = ExamDojoFeatureFlag;

  readonly isRoutingLoading$ = this.router.events.pipe(
    map((event) => {
      if (event instanceof NavigationStart) {
        return true;
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
      ) {
        return false;
      }

      return null;
    }),
    filter((isLoading) => isLoading !== null),
    startWith(true),
  );

  readonly isInitialRoutingLoading$ = this.isRoutingLoading$.pipe(
    takeWhile(
      Boolean,
      // Include the first `false` value
      true,
    ),
  );

  readonly state = connectState({
    showFullScreenLoader: combineLatest([this.appLoadingService.isLoading$, this.isInitialRoutingLoading$]).pipe(
      map(([appLoading, routingLoading]) => appLoading || routingLoading),
    ),
    showLoadingBar: this.isRoutingLoading$.pipe(
      switchMap((isLoading) => {
        if (isLoading) {
          return timer(200).pipe(
            map(() => isLoading),
            startWith(false),
          );
        }
        return of(false);
      }),
    ),
  });
}
