import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { connectState } from '@examdojo/angular/util';
import { ProfileAvatarComponent, UserQuery } from '@examdojo/user';
import { TranslocoPipe } from '@jsverse/transloco';
import { RootUrlParts } from '../../app.model';
import { FEATURE_TO_ICON, FEATURE_TO_LABEL_KEY, Features } from '../../features/features.model';

@Component({
  selector: 'dojo-sidenav',
  imports: [RouterLink, TranslocoPipe, RouterLinkActive, ProfileAvatarComponent],
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavComponent {
  constructor(
    private readonly userQuery: UserQuery,
    private readonly router: Router,
  ) {}

  @Input() isCompact?: boolean;
  @Input() isMobile?: boolean;

  readonly featurePages = [
    Features.TopicPractice,
    Features.LearnHub,
    Features.Assessments,
    // Features.Leaderboards,
  ].map((feature) => ({
    route: feature,
    label: FEATURE_TO_LABEL_KEY[feature],
    icon: FEATURE_TO_ICON[feature],
  }));

  readonly state = connectState({
    user: this.userQuery.active$,
  });

  protected readonly RootUrlParts = RootUrlParts;

  navigateToProfile() {
    this.router.navigate([RootUrlParts.Settings]);
  }
}
