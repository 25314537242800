import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { SyllabusService } from '@examdojo/category/v2';
import { ErrorHandlerService } from '@examdojo/error-handling';
import { mapToVoid } from '@examdojo/rxjs';
import { UserQuery } from '@examdojo/user';
import { combineLatest, filter, first, tap } from 'rxjs';

export const syllabusesResolver: ResolveFn<void> = () => {
  const syllabusService = inject(SyllabusService);
  const userQuery = inject(UserQuery);
  const user$ = userQuery.active$.pipe(filter(Boolean));
  const errorHandlerService = inject(ErrorHandlerService);

  return combineLatest([syllabusService.fetchSyllabuses().pipe(first()), user$.pipe(first(Boolean))]).pipe(
    tap(([syllabuses, user]) => {
      if (!syllabuses.length) {
        errorHandlerService.error(`[syllabusResolver]: Empty list of syllabuses returned from the server`);
        return;
      }

      const syllabusId = user.syllabus_id;
      const courseLevel = user.course_level;

      if (!syllabusId || !courseLevel) {
        return;
      }

      if (!syllabuses.some(({ id }) => id === user.syllabus_id)) {
        errorHandlerService.error(`[syllabusResolver]: User holds a syllabus id that does not exist`);
        return;
      }

      syllabusService.setActive(syllabusId, courseLevel);
    }),
    mapToVoid(),
  );
};
