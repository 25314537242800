import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SyllabusQuery } from '@examdojo/category/v2';
import { PosthogErrorHandlingService } from '@examdojo/core/error-handling/posthog/posthog-error-handling.service';
import { AuthService, UserQuery } from '@examdojo/user';
import { combineLatest, filter, first, map, merge, switchMap, take, tap } from 'rxjs';

@Injectable()
export class PosthogUserTraitsService {
  constructor(
    private readonly authService: AuthService,
    private readonly userQuery: UserQuery,
    private readonly posthogErrorHandlingService: PosthogErrorHandlingService,
    private readonly syllabusQuery: SyllabusQuery,
  ) {
    merge(this.setIdentityOnLogin(), this.setCourse(), this.resetOnLogout()).pipe(takeUntilDestroyed()).subscribe();
  }

  private readonly onboardedUser$ = this.authService.currentUser$.pipe(
    filter(Boolean),
    take(1),
    switchMap(() =>
      this.userQuery.isUserOnboarded$.pipe(
        first(Boolean),
        switchMap(() => this.userQuery.active$),
        first(Boolean),
      ),
    ),
  );

  private setIdentityOnLogin() {
    return this.onboardedUser$.pipe(
      tap((user) => {
        this.posthogErrorHandlingService.identifyUser(user.id, {
          name: user.fullName,
          email: user.email,
        });
      }),
    );
  }

  private setCourse() {
    return combineLatest([this.onboardedUser$, this.syllabusQuery.active$]).pipe(
      map(([user, syllabus]) => {
        if (syllabus) {
          return {
            syllabus: syllabus.name,
            course_level: user.course_level,
          };
        }

        return;
      }),
      filter(Boolean),
      take(1),
      tap((traits) => {
        this.posthogErrorHandlingService.setUserTraits(traits);
      }),
    );
  }

  private resetOnLogout() {
    return this.authService.hasLoggedOut$.pipe(
      tap(() => {
        this.posthogErrorHandlingService.resetUser();
      }),
    );
  }
}
