import { Type } from '@angular/core';
import { AssessmentEffectService } from './assessment-effect.service';
import { FetchPaceBoundariesOnCourseLevelChangeEffectService } from './fetch-pace-boundaries-on-course-level-change-effect.service';
import { HandleEmailVerifiedService } from './handle-email-verified.service';
import { PosthogUserTraitsService } from './posthog-user-traits.service';
import { PracticeActivityRealtimeService } from './practice-activity-realtime.service';
import { SyncPracticeActivityStateService } from './sync-practice-activity-state.service';
import { UpdateUserMarksOnAttemptGradedService } from './update-user-marks-on-attempt-graded.service';

export const APP_EFFECTS: Array<Type<unknown>> = [
  PosthogUserTraitsService,
  FetchPaceBoundariesOnCourseLevelChangeEffectService,
  HandleEmailVerifiedService,
  AssessmentEffectService,
  PracticeActivityRealtimeService,
  SyncPracticeActivityStateService,
  UpdateUserMarksOnAttemptGradedService,
];
