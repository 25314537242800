import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { ErrorHandlerService } from '@examdojo/core/error-handling';
import { combineLatest, of } from 'rxjs';
import { UserMarksService } from './user-marks.service';

export const userMarksResolver: ResolveFn<unknown> = () => {
  const userMarksService = inject(UserMarksService);
  const errorHandlerService = inject(ErrorHandlerService);

  return combineLatest([
    userMarksService.fetchLastWeekUserMarks(),
    userMarksService.getUserSpentPointsAndLimits(),
  ]).pipe(errorHandlerService.catchHttpErrors(() => of(null)));
};
