import { Route } from '@angular/router';
import { AuthUrlParts, redirectToGuard } from '@examdojo/auth';
import { billingInfoResolver } from '@examdojo/billing';
import { gradeBoundariesResolver, topicLevel2ProficienciesResolver, topicsTreeResolver } from '@examdojo/category/v2';
import { NotFoundComponent } from '@examdojo/core/not-found';
import { practiceActivitiesResolver } from '@examdojo/practice-activity';
import { RootUrlParts } from './app.model';
import { AuthGuard } from './auth/auth.guard';
import { authRoutes } from './auth/auth.routes';
import { LEARN_HUB_ROUTES } from './features/learn-hub/learn-hub.routes';
import { syllabusesResolver } from './features/learn-hub/syllabus.resolver';
import { contentReviewerGuard } from './features/question-validation/content-reviewer-guard';
import { topicPracticeResolver } from './features/topic-practice-v2/topic-practice-resolver';
import { onboardingGuard } from './onboarding/onboarding.guard';
import { onboardingRoutes } from './onboarding/onboarding.routes';
import { ShellComponent } from './shell/shell.component';
import { userMarksResolver } from './user/mark/user-marks.resolver';
import { userSettingsResolver } from './user/user-settings-resolver';

export const appRoutes: Route[] = [
  ...authRoutes,
  ...onboardingRoutes,
  {
    path: RootUrlParts.QuestionValidation,
    canActivate: [AuthGuard, redirectToGuard],
    loadComponent: () =>
      import('./features/question-validation/question-validation.component').then((m) => m.QuestionValidationComponent),
  },
  {
    path: '',
    component: ShellComponent,
    canActivate: [AuthGuard, redirectToGuard, contentReviewerGuard, onboardingGuard],
    providers: [],
    resolve: {
      topicsTree: topicsTreeResolver,
      gradeBoundaries: gradeBoundariesResolver,
      syllabuses: syllabusesResolver,
      billing: billingInfoResolver,
      userSettings: userSettingsResolver,
      topicLevel2ProficienciesResolver: topicLevel2ProficienciesResolver,
      userMarksResolver: userMarksResolver,
      practiceActivities: practiceActivitiesResolver,
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: RootUrlParts.TopicPractice,
      },
      {
        path: RootUrlParts.TopicPractice,
        loadComponent: () =>
          import('./features/topic-practice-v2/topic-practice-v2.component').then((m) => m.TopicPracticeV2Component),
        data: { compactView: false },
        resolve: [topicPracticeResolver],
      },
      {
        path: RootUrlParts.Settings,
        loadChildren: () => import('./settings/settings.routes').then((m) => m.PROFILE_ROUTES),
        data: { compactView: false },
      },

      {
        path: RootUrlParts.Assessments,
        loadComponent: () => import('./features/assessments/assessments.component').then((m) => m.AssessmentsComponent),
        data: { compactView: false },
      },
      {
        path: RootUrlParts.Leaderboards,
        loadComponent: () =>
          import('./features/leaderboards/leaderboards.component').then((m) => m.LeaderboardsComponent),
        data: { compactView: false },
      },

      ...LEARN_HUB_ROUTES,
    ],
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

export const ROUTES_WITH_TRANSITION = [...Object.values(AuthUrlParts), RootUrlParts.Onboarding];
