import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PaceBoundaryService } from '@examdojo/category/v2';
import { ErrorHandlerService } from '@examdojo/core/error-handling';
import { UserQuery } from '@examdojo/user';
import { EMPTY, merge, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FetchPaceBoundariesOnCourseLevelChangeEffectService {
  constructor(
    private readonly paceBoundaryService: PaceBoundaryService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly userQuery: UserQuery,
  ) {
    merge(this.userQuery.activeCourseLevel$)
      .pipe(
        this.errorHandlerService.setHttpErrorMetadata({
          entity: 'examdojo.entity.pace_boundary',
        }),
        switchMap((courseLevel) =>
          this.paceBoundaryService
            .fetchPaceBoundariesByCourseLevel(courseLevel)
            .pipe(this.errorHandlerService.catchHttpErrors(() => EMPTY)),
        ),

        takeUntilDestroyed(),
      )
      .subscribe();
  }
}
