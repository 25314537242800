import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UserService } from '@examdojo/user';
import { map } from 'rxjs';
import { RootUrlParts } from '../../app.model';

export const contentReviewerGuard: CanActivateFn = () => {
  const router = inject(Router);
  const userService = inject(UserService);

  return userService.fetchCurrentUserRole().pipe(
    map((userRole) => {
      if (userRole === 'CONTENT_CREATOR') {
        return router.createUrlTree([`/${RootUrlParts.QuestionValidation}`]);
      }
      return true;
    }),
  );
};
