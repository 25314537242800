<div class="sidenav-wrap bg-neutral-0 group h-full p-4" [class.is-compact]="isCompact" [class.is-mobile]="isMobile">
  <div class="sidenav-inner flex h-full flex-col">
    <a
      [routerLink]="'/'"
      class="logo-link mb-6 mt-2 flex h-[48px] rounded-[0.75rem] px-4 transition-colors hover:bg-neutral-50 group-[.is-compact]:px-2"
    >
      @if (isCompact) {
        <img src="assets/images/logo/logo-compact.svg" alt="Exam dojo" class="w-[40px]" />
      } @else {
        <img src="assets/images/logo/logo-color-horizontal.svg" alt="Exam dojo" class="w-[148px]" />
      }
    </a>

    <div class="sidenav-inner-links flex grow flex-col gap-2">
      @for (page of featurePages; track page.route) {
        <a
          class="sidebar-link text-secondary text-md flex items-center gap-4 px-4 py-2 font-semibold group-[.is-compact]:px-2"
          [routerLink]="page.route"
          routerLinkActive="active"
        >
          <div class="aligner h-[32px] w-[32px]">
            <img [src]="page.icon" [alt]="page.label" />
          </div>
          <span class="sidebar-link-text">{{ page.label | transloco }}</span>
        </a>
      }
      @if (state.user) {
        <a
          class="sidebar-link sidebar-link-user text-secondary mt-auto flex items-center gap-4 rounded-[0.75rem] px-4 py-2 font-medium transition-colors hover:bg-neutral-50 group-[.is-compact]:px-2"
          [routerLink]="RootUrlParts.Settings"
          routerLinkActive="active"
        >
          <dojo-profile-avatar [size]="30" class="shrink-0" (clicked)="navigateToProfile()" />
          <span class="sidebar-link-text text-md truncate">{{ state.user.fullName }}</span>
        </a>
      }
    </div>
  </div>
</div>
