import { Injectable } from '@angular/core';
import { UserHttpModel } from '@examdojo/models/user';
import { ExamdojoSupabaseService } from '@examdojo/supabase';
import { from } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserMarksHttpService {
  constructor(private readonly supabase: ExamdojoSupabaseService) {}

  fetchLastWeekDailyMarks(timezone: string) {
    return from(
      this.supabase.client
        .rpc('get_weekly_marks', {
          tz: timezone,
        })
        .throwOnError()
        .then((response) => response.data),
    );
  }

  getUserMarksAttemptsAndLimits(userId: UserHttpModel['id']) {
    return from(
      this.supabase.client
        .schema('learning')
        .from('user_mark_attempts_and_limits')
        .select()
        .eq('user_id', userId)
        .single()
        .throwOnError()
        .then((response) => response.data!),
    );
  }
}
