<div class="mx-auto flex w-full max-w-[300px] items-center justify-center overflow-hidden">
  <div class="animated-sensei z-1 absolute flex items-center justify-center">
    <img src="assets/images/sensei/sensei-shocked.svg" alt="Dojo" />
  </div>

  <div class="animated-block-text z-1 relative">
    @if (header) {
      <p class="text-display-xl text-neutral-0 mb-5 text-center font-semibold">{{ header }}</p>
    }

    @if (description) {
      <p class="text-md text-neutral-0 mx-auto mb-6 max-w-[90%] text-center">{{ description }}</p>
    } @else {
      <div
        class="text-md text-neutral-0 mx-auto mb-6 max-w-[90%] text-center"
        [class.hidden]="!ngContentDescription.children.length"
        #ngContentDescription
      >
        <ng-content select="[y42FullscreenMessageDescription]" />
      </div>
    }

    <div class="text-center">
      <div #ngContentFooter>
        <ng-content select="[y42FullscreenMessageFooter]" />
      </div>
      @if (!ngContentFooter.children.length) {
        <dojo-button (click)="goToHome()" class="mx-auto flex w-fit">Return Home</dojo-button>
      }
    </div>
  </div>
</div>
