import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ButtonComponent } from '@examdojo/ui/button';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'y42-fullscreen-message',
  templateUrl: './fullscreen-message.component.html',
  styleUrls: ['./fullscreen-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'flex' },
  imports: [MatButtonModule, ButtonComponent, ButtonComponent],
})
export class FullscreenMessageComponent {
  @HostBinding('class.h-[100vh]')
  @Input()
  fullHeight = true;

  @Input()
  header = '';

  @Input()
  description = '';

  goToHome() {
    window.location.href = '/';
  }
}
