import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, inject, isDevMode, LOCALE_ID } from '@angular/core';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  PreloadAllModules,
  provideRouter,
  Router,
  RouteReuseStrategy,
  withComponentInputBinding,
  withPreloading,
  withViewTransitions,
} from '@angular/router';
import { ExamdojoAuthService, provideAuthTokenInterceptor } from '@examdojo/auth';
import { environment } from '@examdojo/core/environment';
import { provideFeatureFlag } from '@examdojo/core/feature-flag';
import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from '@examdojo/core/i18n';
import { provideToastConfig } from '@examdojo/core/toast';
import { provideExamdojoTranslations } from '@examdojo/i18n';
import { EXAMDOJO_FEATURE_FLAGS } from '@examdojo/models/feature-flag';
import { LayoutBreakpoint, provideLayoutBreakpoints } from '@examdojo/platform';
import { AuthService } from '@examdojo/user';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { provideTransloco } from '@jsverse/transloco';
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { provideMarkdown } from 'ngx-markdown';
import { NgxStripeModule } from 'ngx-stripe';
import { APP_INITIALIZER_PROVIDER } from './app-initializer';
import { LARGE_SCREEN_WIDTH, MEDIUM_SCREEN_WIDTH, SMALL_SCREEN_WIDTH } from './app.model';
import { appRoutes, ROUTES_WITH_TRANSITION } from './app.routes';
import { MarkedOptionsProvider } from './chat/models';
import { TranslocoHttpLoader } from './shared/i18n/transloco-loader';

export const appConfig: ApplicationConfig = {
  providers: [
    APP_INITIALIZER_PROVIDER,
    provideAnimations(),
    provideRouter(
      appRoutes,
      withPreloading(PreloadAllModules),
      withComponentInputBinding(),
      withViewTransitions({
        onViewTransitionCreated: ({ transition }) => {
          const router = inject(Router);

          const targetUrlTree = router.getCurrentNavigation()!.finalUrl!;
          const targetUrl = targetUrlTree.toString();
          const applyTransition = ROUTES_WITH_TRANSITION.some((url) => `/${url}` === targetUrl);

          if (!applyTransition) {
            transition.skipTransition();
          }
        },
      }),
    ),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(AngularSvgIconModule.forRoot()),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideToastConfig(),
    importProvidersFrom(NgxStripeModule.forRoot(environment.stripe.key)),
    {
      provide: ExamdojoAuthService,
      useExisting: AuthService,
    },
    AuthService,
    provideTransloco({
      config: {
        availableLangs: SUPPORTED_LANGUAGES,
        defaultLang: DEFAULT_LANGUAGE,
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    provideTranslocoMessageformat(),
    provideExamdojoTranslations(),
    provideIonicAngular({
      swipeBackEnabled: false,
      useSetInputAPI: true,
    }),
    provideMarkdown({
      markedOptions: MarkedOptionsProvider,
    }),
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        showDelay: 500,
        hideDelay: 0,
        touchGestures: 'off',
      } satisfies Partial<MatTooltipDefaultOptions>,
    },
    provideAuthTokenInterceptor(),
    provideFeatureFlag(EXAMDOJO_FEATURE_FLAGS),
    { provide: LOCALE_ID, useValue: navigator.language },
    provideLayoutBreakpoints({
      [LayoutBreakpoint.Small]: SMALL_SCREEN_WIDTH,
      [LayoutBreakpoint.Medium]: MEDIUM_SCREEN_WIDTH,
      [LayoutBreakpoint.Large]: LARGE_SCREEN_WIDTH,
    }),
  ],
};
