import { topicsTreeResolver } from '@examdojo/category/v2';
import { RootUrlParts } from '../app.model';
import { AuthGuard } from '../auth/auth.guard';
import { syllabusesResolver } from '../features/learn-hub/syllabus.resolver';
import { alreadyOnboardedGuard } from './onboarding.guard';

export const onboardingRoutes = [
  {
    path: RootUrlParts.Onboarding,
    canActivate: [AuthGuard, alreadyOnboardedGuard],
    resolve: {
      syllabuses: syllabusesResolver,
      topicsTree: topicsTreeResolver,
    },
    loadComponent: () => import('./onboarding.component').then((m) => m.OnboardingComponent),
  },
];
