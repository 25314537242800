import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PlatformService } from '@examdojo/platform';
import {
  PracticeActivityHttpModel,
  PracticeActivityQuery,
  PracticeActivityService,
  PracticeActivityStore,
} from '@examdojo/practice-activity';
import { QuestionService } from '@examdojo/question';
import { RealtimeChangesListenEvent, RealtimeService } from '@examdojo/supabase';
import { UserQuery } from '@examdojo/user';
import { concatMap, filter, map, merge, of, switchMap, withLatestFrom } from 'rxjs';
import { TopicPracticeDialogService } from '../features/topic-practice-v2/dialog/topic-practice-dialog.service';

@Injectable()
export class PracticeActivityRealtimeService {
  constructor(
    private readonly realtimeService: RealtimeService,
    private readonly userQuery: UserQuery,
    private readonly store: PracticeActivityStore,
    private readonly query: PracticeActivityQuery,
    private readonly practiceActivityService: PracticeActivityService,
    private readonly questionService: QuestionService,
    private readonly platformService: PlatformService,
    private readonly topicPracticeDialogService: TopicPracticeDialogService,
  ) {
    merge(this.listenForPracticeActivityInProgressStatusChange()).pipe(takeUntilDestroyed()).subscribe();
  }

  private listenForPracticeActivityInProgressStatusChange() {
    return this.userQuery.active$.pipe(
      filter(Boolean),
      switchMap((user) =>
        this.realtimeService.listenBroadcastRealtime<PracticeActivityHttpModel>(
          [{ event: RealtimeChangesListenEvent.UPDATE }],
          `practice_activity_status:${user.id}`,
        ),
      ),
      withLatestFrom(this.platformService.activeStatusState$),
      filter(([_, isActive]) => isActive),
      map(([event]) => event),
      concatMap((payload) => {
        if (
          payload.event === RealtimeChangesListenEvent.UPDATE &&
          payload?.payload?.record?.id &&
          payload.payload.record.status === 'IN_PROGRESS' &&
          this.query.getEntity(payload.payload.record.id)?.status !== 'IN_PROGRESS'
        ) {
          return this.handleInProgressPracticeActivityStatusChange(payload.payload.record);
        }

        if (
          payload.event === RealtimeChangesListenEvent.UPDATE &&
          payload?.payload?.record?.id &&
          this.store.hasEntity(payload.payload.record.id) &&
          payload.payload.record.status === 'COMPLETED' &&
          this.query.getEntity(payload.payload.record.id)?.status !== 'COMPLETED'
        ) {
          // TODO: after refactoring the status logic
          return this.handleDiscardedPracticeActivityStatusChange(payload.payload.record);
        }

        // TODO: after refactoring the status logic
        // if (
        //   payload.event === RealtimeChangesListenEvent.UPDATE &&
        //   payload?.payload?.record?.id &&
        //   this.store.hasEntity(payload.payload.record.id) &&
        //   (payload.payload.record.status === 'DISCARDED' || payload.payload.record.status === 'FAILED')
        // ) {
        //   return this.handleDiscardedPracticeActivityStatusChange(payload.payload.record);
        // }

        return of(null);
      }),
    );
  }

  private handleInProgressPracticeActivityStatusChange(practiceActivityHttpModel: PracticeActivityHttpModel) {
    this.store.upsert(practiceActivityHttpModel.id, practiceActivityHttpModel);
    return of(null);
  }

  // private handleCompletedPracticeActivityStatusChange(practiceActivityHttpModel: PracticeActivityHttpModel) {}

  private handleDiscardedPracticeActivityStatusChange(practiceActivityHttpModel: PracticeActivityHttpModel) {
    // Do not update the store if the practice activity is being created. It is happening, when current client triggered the creation of the practice activity.
    if (this.practiceActivityService.creatingPracticeActivity$$.value) {
      return of(null);
    }
    this.questionService.resetQuestionContext();
    this.store.update(practiceActivityHttpModel.id, practiceActivityHttpModel);

    return this.topicPracticeDialogService.dismissPracticeActivityDialog();
  }
}
