import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ChildActivationEnd, Router, RouterOutlet } from '@angular/router';
import { connectState } from '@examdojo/angular/util';
import { PlatformService } from '@examdojo/platform';
import { combineLatest, distinctUntilChanged, filter, map, startWith } from 'rxjs';
import { SidenavComponent } from './sidenav/sidenav.component';

@Component({
  selector: 'dojo-shell',
  imports: [RouterOutlet, MatSidenavModule, SidenavComponent],
  templateUrl: './shell.component.html',
  styleUrl: './shell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'h-full' },
})
export class ShellComponent {
  constructor(
    private readonly platformService: PlatformService,
    private readonly router: Router,
  ) {}

  readonly isMobile$ = this.platformService.isSmall$.pipe(distinctUntilChanged());

  readonly isCompact$ = combineLatest([
    // Check if screen width is less than 1100px
    this.platformService.resize$.pipe(
      startWith(null),
      map(() => this.platformService.width < 1100),
      distinctUntilChanged(),
    ),
    // Check if the current route has compactView: true in its data
    this.router.events.pipe(
      filter((e) => e instanceof ChildActivationEnd && e.snapshot.component === ShellComponent),
      map((ev) => (ev as ChildActivationEnd).snapshot.firstChild?.data?.['compactView'] ?? false),
    ),
  ]).pipe(
    // If either condition is true, the view will be compact
    map(([isScreenSmall, isCompactView]) => {
      return isScreenSmall || isCompactView;
    }),
  );

  readonly state = connectState({
    isCompact: this.isCompact$,
    isMobile: this.isMobile$,
  });
}
