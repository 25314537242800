import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SyllabusQuery } from '@examdojo/category/v2';
import { ErrorHandlerService } from '@examdojo/error-handling';
import { EMPTY, switchMap } from 'rxjs';
import { AssessmentService } from '../features/assessments/assessment.service';

@Injectable({
  providedIn: 'root',
})
export class AssessmentEffectService {
  constructor(
    private readonly assessmentService: AssessmentService,
    private readonly syllabusQuery: SyllabusQuery,
    private readonly errorHandlerService: ErrorHandlerService,
  ) {
    this.fetchAssessmentsWhenSyllabusChanges().pipe(takeUntilDestroyed()).subscribe();
  }

  private fetchAssessmentsWhenSyllabusChanges() {
    return this.syllabusQuery.activeIdAndCourseLevel$.pipe(
      switchMap(({ syllabusId, courseLevel }) => this.assessmentService.fetchAll({ syllabusId, courseLevel })),
      this.errorHandlerService.catchHttpErrors(() => EMPTY),
    );
  }
}
