import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { AppLoadingService } from '@examdojo/app-state';
import { TOPIC_PRACTICE_DIALOG_LOADER_ID } from './topic-practice-v2.model';

/**
 * A fake resolver that sets the loading state
 * before OpenPracticeDialogWhenActive is initialized.
 */
export const topicPracticeResolver: ResolveFn<void> = () => {
  const appLoadingService = inject(AppLoadingService);

  // Set the loader only for the first time.
  // Otherwise the loader will be shown after switching between the modes.
  appLoadingService.setLoadingOnce({ id: TOPIC_PRACTICE_DIALOG_LOADER_ID });
};
