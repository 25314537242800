import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PlatformService } from '@examdojo/platform';
import { PracticeActivityService, PracticeActivityStoreModel } from '@examdojo/practice-activity';
import { QuestionAttemptHttpService, QuestionQuery, QuestionService } from '@examdojo/question';
import { catchError, EMPTY, forkJoin, switchMap } from 'rxjs';
import { TopicPracticeDialogService } from '../features/topic-practice-v2/dialog/topic-practice-dialog.service';
import { UserMarksService } from '../user/mark/user-marks.service';

@Injectable()
export class SyncPracticeActivityStateService {
  constructor(
    private readonly practiceActivityService: PracticeActivityService,
    private readonly questionService: QuestionService,
    private readonly questionAttemptHttpService: QuestionAttemptHttpService,
    private readonly questionQuery: QuestionQuery,
    private readonly topicPracticeDialogService: TopicPracticeDialogService,
    private readonly userMarksService: UserMarksService,
    private readonly platformService: PlatformService,
  ) {
    this.syncStoreWhenBackOnline().pipe(takeUntilDestroyed()).subscribe();
  }

  private syncStoreWhenBackOnline() {
    return this.platformService.trigger$.pipe(
      switchMap(() =>
        forkJoin([
          this.practiceActivityService.fetchAll(),
          this.userMarksService.fetchLastWeekUserMarks(),
          this.userMarksService.getUserSpentPointsAndLimits(),
        ]).pipe(
          switchMap(([practiceActivities]) => {
            if (!practiceActivities.length && this.topicPracticeDialogService.isOpen()) {
              return this.topicPracticeDialogService.dismissPracticeActivityDialog();
            }

            return this.syncQuestionAttemptStoreIfAnyChangeHasHappened(practiceActivities);
          }),

          catchError(() => EMPTY),
        ),
      ),
    );
  }

  private syncQuestionAttemptStoreIfAnyChangeHasHappened(practiceActivities: PracticeActivityStoreModel[]) {
    const currentQuestionAttempt = this.questionQuery.getValue()?.attempt;

    if (
      practiceActivities &&
      practiceActivities[0] &&
      !!practiceActivities[0].question_attempt_id &&
      !!currentQuestionAttempt &&
      practiceActivities[0].question_attempt_id === currentQuestionAttempt.id
    ) {
      return this.questionAttemptHttpService.fetch(practiceActivities[0].question_attempt_id).pipe(
        switchMap((fetchedAttempt) => {
          if (fetchedAttempt.updated_at !== currentQuestionAttempt.updated_at) {
            return this.questionService.fetchQuestionContext(currentQuestionAttempt.id);
          }
          return EMPTY;
        }),
      );
    }

    return EMPTY;
  }
}
