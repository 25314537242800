import { SUPPORTED_LANGUAGES } from '@examdojo/core/i18n';
import { provideTranslocoScope } from '@jsverse/transloco';

const translocoLoader = SUPPORTED_LANGUAGES.reduce(
  (acc, lang) => ({
    ...acc,
    [lang]: () => import(`./${lang}.json`),
  }),
  {},
);

export function provideExamdojoTranslations() {
  return provideTranslocoScope({
    scope: 'examdojo',
    loader: translocoLoader,
  });
}
