import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@examdojo/core/error-handling';
import { mapToVoid } from '@examdojo/core/rxjs';
import { UserUIModel } from '@examdojo/models/user';
import { UserQuery } from '@examdojo/user';
import { first, switchMap, tap } from 'rxjs';
import { UserSettingsService } from '../../user/user-settings.service';
import { UserMarksHttpService } from './user-marks-http.service';
import { UserMarksStore } from './user-marks.store';

@Injectable({ providedIn: 'root' })
export class UserMarksService {
  constructor(
    private readonly httpService: UserMarksHttpService,
    private readonly store: UserMarksStore,
    private readonly errorHandler: ErrorHandlerService,
    private readonly userQuery: UserQuery,
    private readonly userSettingsService: UserSettingsService,
  ) {}

  fetchLastWeekUserMarks() {
    return this.userSettingsService.timezone$.pipe(
      first(),
      switchMap((timezone) => this.httpService.fetchLastWeekDailyMarks(timezone ?? 'UTC')),
      tap((data) => this.store.updateStore((state) => ({ ...state, dailyMarks: data }))),
      this.errorHandler.setHttpErrorMetadata({ entity: 'examdojo.entity.user_marks' }),
      mapToVoid(),
    );
  }

  getUserSpentPointsAndLimits() {
    return this.userQuery.active$.pipe(
      first(Boolean),
      switchMap((active) => this.fetchUserMarksAttemptsAndLimits(active.id)),
      mapToVoid(),
    );
  }

  private fetchUserMarksAttemptsAndLimits(userId: UserUIModel['id']) {
    return this.httpService.getUserMarksAttemptsAndLimits(userId).pipe(
      tap((data) =>
        this.store.updateStore((state) => ({
          ...state,
          userMarksAttemptsAndLimits: {
            point_limit: data.point_limit,
            spent_points: data.spent_points ?? 0,
            next_refresh: data.next_refresh,
            user_type: data.user_type,
          },
        })),
      ),
      this.errorHandler.setHttpErrorMetadata({ entity: 'examdojo.entity.user_marks' }),
    );
  }
}
