import { bootstrapApplication } from '@angular/platform-browser';
import { environment } from '@examdojo/core/environment';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { enableElfProdMode } from '@ngneat/elf';
import {
  LicenseManager as AgGridLicenseManager,
  AllEnterpriseModule,
  ModuleRegistry,
  provideGlobalGridOptions,
} from 'ag-grid-enterprise';
import posthog from 'posthog-js';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

if (environment.production_build) {
  enableElfProdMode();
}

AgGridLicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-073708}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Datos-Intelligence_GmbH}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Datos}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Datos}_need_to_be_licensed___{Datos}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{13_January_2026}____[v3]_[01]_MTc2ODI2MjQwMDAwMA==46cd382259615b08c9442fe6bfa4e7fa',
);

ModuleRegistry.registerModules([AllEnterpriseModule]);

provideGlobalGridOptions({ theme: 'legacy' });

if (environment.posthog.key) {
  posthog.init(environment.posthog.key, {
    api_host: environment.posthog.apiHost,
    ui_host: environment.posthog.uiHost,
    person_profiles: 'identified_only',
  });
}

defineCustomElements(window);

bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));
