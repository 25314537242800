@if (state.isMobile) {
  <dojo-sidenav [isMobile]="state.isMobile" class="mobile-view safe-area-bottom" />
  <div class="shell-container-mobile">
    <router-outlet />
  </div>
} @else {
  <mat-sidenav-container class="shell-container h-full">
    <mat-sidenav [disableClose]="true" mode="side" [opened]="true" [style.width.px]="state.isCompact ? 84 : 240">
      <dojo-sidenav [isCompact]="state.isCompact" />
    </mat-sidenav>
    <mat-sidenav-content class="h-full bg-white" [style.margin-left.px]="state.isCompact ? 84 : 240">
      <router-outlet />
    </mat-sidenav-content>
  </mat-sidenav-container>
}
