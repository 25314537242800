<ion-app class="exam">
  @if (state.showFullScreenLoader) {
    <div class="absolute z-[1000000] flex h-full w-full items-center justify-center bg-white">
      <dojo-loader [centered]="true" />
    </div>
  } @else {
    @if (state.showLoadingBar) {
      <mat-progress-bar mode="indeterminate" @appearDisappearWithHeight></mat-progress-bar>
    }
  }

  <router-outlet />

  <dojo-debugging-window *ngIfFeatureFlag="ExamDojoFeatureFlag.Testing" />
</ion-app>
